<template>
    <div class="body">
        <div class="container" style="width: 100%;">
            <div class="question-list" v-if="list">
                <div class="question-item" v-for="(item, index) in list" :key="index">
                    <!-- <div class="category"></div> -->
                    <div class="question-title">
                        {{index+1}} {{item.title}}
                        <span class="light-color">
                            （{{item.category}}）（{{item.score}}分）
                        </span>
                    </div>
                    <div class="question-detail">
                        <div class="option" 
                        v-for="(o, i) in strToArray(item.options)" 
                        :key="i">{{letters[i]}} {{o}}</div>
                    </div>
                    <div class="question-result" >
                        <div class="option">
                          {{isCorrect(item)?'答题正确':'答题错误'}}：
                          <span class="answer" 
                          :class="isCorrect(item)?'right':'error'"
                          >{{indexToLetter(item)}}
                          </span>
                        </div>
                        <div class="option">正确答案是：{{indexToLetter2(item)}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { getExamResultDetail } from '../../api/task.js';
    import { checkLogin } from '@/utils/common.js';
    import { mapState } from 'vuex';
    var examid;
    export default{
        activated() {
            examid = this.$route.query.examid;
            checkLogin().then(()=> {
                this.getResultDetail();
            })
             this.hideTab(1);
        },
        data() {
          return {
              list: null,
              letters: ['A', 'B', 'C', 'D', 'E', 'F', 'G'],
          }  
        },
        computed: mapState(['token']),
        methods:{
           getResultDetail(){
               getExamResultDetail(examid).then((ret)=> {
                   if(ret){
                       this.list = ret.data;
                   }
               })
            },
            strToArray(str){
                return str.split('|');
            },
            indexToLetter(item){
                var str = item.result;
                if(str === null || typeof('str') == 'string' && !str.length){
                    return '-';
                }
                var arr = this.strToArray(str);
                var that = this;
                return arr.reduce((memo, cur)=>{ return memo + that.letters[Number(cur)]; }, '')
            },
            indexToLetter2(item){
                var str = item.answer;
                var arr = this.strToArray(str);
                var that = this;
                return arr.reduce((memo, cur)=>{ return memo + that.letters[Number(cur)]; }, '')
            },
            isCorrect(item){
                if(item.status == 1 && item.answer == item.result){
                    return true;
                }
                return false;
            },
            hideTab(){
                //console.log(document.querySelector('#vantab'));
                document.querySelector('#vantab').classList.add('tab-hide');
            }
        }
    }
</script>

<style scoped>
    .question-item{
        background-color: #fff;
        padding: 1rem 1.5rem ;
        font-size: 14px;
        border-radius: 3px;
        height: 100%;
    }
    .question-item .category{
        color: #465ecc;
    }
    .question-item .question-title{
        padding: 9px 0;
    }
    .question-item .question-detail .option{
        height: 30px;
        line-height: 30px;
    }
    .question-item .question-result{
        display: flex;
    }
    .question-item .option{
        padding-right: 20px;
    }
    .question-item .answer{
        display: inline-block;
        min-width: 10px;
        text-align: center;
    }
    .question-item .answer.error{
        border-bottom: 1px solid #fa4b48;
    }
    .question-item .answer.right{
        border-bottom: 1px solid #07c160;
    }
    /* .van-tabbar--fixed{
        display:none!important;
    } */
</style>